import { MailFilled } from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { Links } from "../../handlers/enums";

export default function ComponentFooter(props) {
  const { t } = useTranslation();

  return (
    <>
      <Footer className="border-t bg-custom-black text-custom-white flex justify-center">
        <a href={Links.MailTo}>
          <MailFilled className="text-xl" />
        </a>
        <p className="text-xs my-auto ml-3">{t("copyright_date")} &#169; <a href={Links.Vvoids} rel="noreferrer noopener" target="_blank"><span>{t("virtual_voids")}</span></a></p>
      </Footer>
    </>
  );
}
