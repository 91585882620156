export const QueryId = {
    SelectUserBooks: "v1/select/user/books",
    SelectGoogleBooks: "v1/select/google/books",
    ToggleUserBook: "v1/toggle/user/book",
    SelectBook: "v1/select/book",
    Login: "v1/login",
    SelectUserBookSessions: "v1/select/user/book/sessions",
    ToggleUserBookComplete: "v1/toggle/user/book/complete",
    UpdateUserBookRating: "v1/update/user/book/rating",
    UpdateUserBookCharacters: "v1/update/user/book/characters",
    UpdateUserBookNotes: "v1/update/user/book/notes",
    DeleteUserBookSession: "v1/delete/user/book/session",
    InsertUserBookSession: "v1/insert/user/book/session",
    UpdateUserBookSession: "v1/update/user/book/session",
    UpdateUserYearGoal: "v1/update/user/yeargoal",
    SelectUserLite: "v1/select/user/lite"

}

export const WebRoutes = {
    Home: "/",
    Book: "book"
}

export const QueryCase = {
    Unknown: 0,

    //Defaults
    Success: 200,
    Error: 400,

    //Customs
    Empty: 260,
    Invalid: 261,
    Expired: 262,
    Unverified: 263,
    Mismatch: 264,
    MissingParameters: 265,
    FailedEmail: 266,
    ContactUs: 267,
    Compressed: 268,
    Undefined: 269,
    WarningDatabase: 270,
}

export const Defaults = {
    GuidEmpty: "00000000-0000-0000-0000-000000000000",
    DateFormat: "YYYY-MM-DD",
    DateTimeFormat: "YYYY-MM-DD HH:mm",
    DateTimeFormatDTB: "YYYY-MM-DDTHH:mm:ss",
    DateFormatFull: "DD MMMM YYYY",
    DateTimeFormatEurope: "DD-MM-YYYY HH:mm",
    TimeFormat: "HH:mm",
    StringEmpty: "",
    UrlPrefix: "https://",
    Space: " ",
    ArrayEmpty: [],
    Nullable: null,
    Number: 0
}

export const Language = {
    Dutch: "nl",
    English: "en",
    EnglishAlt: "gb"
}

export const Color = {
    White: "#F9F9F9",
    Grey: "#39393A",
    Black: "#12130F",
    Blue: "#83BCFF",
    Green: "#7DAA92",
    Yellow: "#E8CB10",
    Red: "#F8719D"
}

export const Section = {
    Search: "section_search",
    TBR: "section_todo",
    Done: "section_done"
}

export const ToastId = {
    ActionSuccess: "toast_mutate_success",
    ActionSuccessUpdate: "toast_mutate_success_update",
    ActionSuccessCreate: "toast_mutate_success_create",
    ActionSuccessDelete: "toast_mutate_success_delete",
    ActionSuccessSubmit: "toast_mutate_success_submit",
    ActionError: "toast_mutate_error",
    ActionWarning: "toast_mutate_warning",
    RequiredFields: "toast_required_fields",
    RequiredHttps: "toast_required_https"
}

export const StorageId = {
    SearchValue: "search_value",
}

export const Regex = {
    Phone: /^\+\d{6,15}$/,
    AlphaNumeric: /^[a-zA-Z0-9]+$/,
    AlphaNumericSpaces: /^[a-zA-Z0-9\s]+$/,
    Numeric: /^[0-9]+$/,
    Alpha: /^[a-zA-Z]+$/,
    SpacedNumber: /\B(?=(\d{3})+(?!\d))/g,
    AnySpecial: /[^a-zA-Z0-9\s]/
}

export const Settings = {
    AxiosLogs: true,
    IsDevelopment: true,
    TrackMissingTranslationKeys: true
}

export const Links = {
    Vvoids: "https://vvoids.com",
    MailTo: "mailto:vince@vvoids.com"
}