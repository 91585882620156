export const IconHeart = () => (<svg width="64" height="55" viewBox="0 0 64 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64 17.7143C64 37.7143 34.3457 53.9029 33.0829 54.5714C32.75 54.7505 32.378 54.8442 32 54.8442C31.622 54.8442 31.25 54.7505 30.9171 54.5714C29.96 54.0571 12.76 44.66 4.43714 31.46C4.32979 31.2871 4.27055 31.0887 4.26555 30.8852C4.26055 30.6817 4.30998 30.4806 4.40872 30.3027C4.50746 30.1247 4.65193 29.9763 4.8272 29.8729C5.00248 29.7695 5.20219 29.7147 5.40571 29.7143H16C16.3764 29.7145 16.747 29.6218 17.079 29.4444C17.411 29.2669 17.694 29.0103 17.9029 28.6971L20.5714 24.6971L27.8114 35.5543C28.0888 35.9703 28.495 36.2839 28.9678 36.447C29.4405 36.6101 29.9536 36.6137 30.4286 36.4571C30.9324 36.2865 31.3635 35.9501 31.6514 35.5029L35.5086 29.7143H41.1429C41.4562 29.715 41.7663 29.6512 42.054 29.527C42.3417 29.4028 42.6007 29.2208 42.8151 28.9922C43.0294 28.7636 43.1945 28.4935 43.3001 28.1984C43.4056 27.9034 43.4493 27.5898 43.4286 27.2771C43.3774 26.6896 43.1061 26.1432 42.6689 25.7473C42.2318 25.3515 41.6611 25.1356 41.0714 25.1429H34.2857C33.9093 25.1426 33.5387 25.2353 33.2067 25.4128C32.8748 25.5902 32.5918 25.8469 32.3829 26.16L29.7143 30.16L22.4743 19.3029C22.1967 18.8862 21.79 18.5722 21.3167 18.4091C20.8434 18.246 20.3296 18.2428 19.8543 18.4C19.3517 18.5716 18.9218 18.9078 18.6343 19.3543L14.7771 25.1429H2.15143C1.90718 25.1435 1.66914 25.0659 1.4722 24.9215C1.27527 24.777 1.12981 24.5732 1.05714 24.34C0.368071 22.1985 0.0115444 19.9639 0 17.7143C0.00529354 13.0178 1.87332 8.51517 5.19424 5.19424C8.51517 1.87332 13.0178 0.00529354 17.7143 0C23.6143 0 28.78 2.53714 32 6.82571C35.22 2.53714 40.3857 0 46.2857 0C50.9822 0.00529354 55.4848 1.87332 58.8058 5.19424C62.1267 8.51517 63.9947 13.0178 64 17.7143Z" fill="white" />
</svg>);

export const IconFeather = () => (<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M60.0437 0L46.0893 4.05837L51.7391 0.234163C48.1722 1.04012 43.6659 2.61663 38.0025 5.16111L28.7585 14.7986L32.0395 8.06637C28.8674 9.83076 25.5047 12.1438 22.2645 14.853L20.1135 23.6069L19.6506 17.1266C16.4513 20.0672 13.4426 23.3346 10.9512 26.779C8.33321 30.3867 6.287 34.1987 5.26186 37.997L1.43357 35.4376C1.77664 38.6641 2.83718 41.4686 4.57707 44.0417L0 42.6394C1.04012 45.8388 2.74052 47.9081 5.61583 49.5826C4.00528 54.1161 3.24969 58.8539 2.43829 63.578L4.94329 64C7.84854 35.0428 28.3501 16.582 46.8789 8.40264L47.8182 10.7688C31.2499 18.1885 18.3709 30.7135 11.6319 48.7658C14.0961 48.7794 16.5058 48.4799 18.7385 47.9081L19.4056 39.6171L21.189 47.1729C22.673 46.6284 24.0344 45.9749 25.246 45.2125L22.4279 39.2904L26.8933 44.0281C27.9825 43.1568 28.881 42.1766 29.5617 41.101C33.7004 34.6343 38.0297 28.2085 46.5521 22.8309L40.3305 20.6254L49.874 20.9113C51.426 20.1217 53.0597 19.1551 54.2986 18.2429L47.5324 17.6984L57.7021 15.3023C58.4917 14.5127 59.2269 13.6822 59.894 12.8517C62.2492 9.86479 63.8421 6.75533 63.4609 3.92767C63.2839 2.51317 62.4398 1.18307 61.0648 0.438375C60.7244 0.261391 60.3569 0.104829 60.0437 0Z" fill="white" />
</svg>);

export const IconPepper = () => (<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.5375 0.377077C54.9875 -0.422897 56.8125 0.0895867 57.6125 1.53954L58.2125 2.627C60.625 6.96436 60.6875 12.0892 58.6375 16.3766C61.975 19.9514 64 24.7388 64 30.0011C64 32.3135 63.6125 34.5385 62.8875 36.6009C62.125 38.7633 59.325 38.6383 58.2875 36.5884L56.825 33.6635C56.3125 32.651 55.275 32.001 54.1375 32.001H45C43.3375 32.001 42 30.6636 42 29.0011V19.0015C42 17.339 40.6625 16.0016 39 16.0016H36.8625C34.2 16.0016 33.1125 13.0142 35.5125 11.8892C38.0875 10.6767 40.9625 10.0018 44 10.0018C47.5375 10.0018 50.85 10.9142 53.725 12.5267C54.4125 10.2518 54.1875 7.72684 52.975 5.55191L52.375 4.46444C51.575 3.01449 52.0875 1.18955 53.5375 0.389577V0.377077ZM21.4 43.1882L33 20.0014H38V30.0011C38 33.3135 40.6875 36.0009 44 36.0009H53.525L56.5125 41.9757C46.5375 55.4878 30.5375 64 12.9 64H5.55C2.4875 64 0 61.5126 0 58.4502C0 55.8503 1.8125 53.6003 4.35 53.0379L10.575 51.6504C15.275 50.6004 19.2625 47.5005 21.4125 43.1882H21.4Z" fill="white" />
</svg>);

export const IconTear = () => (<svg width="46" height="64" viewBox="0 0 46 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.0483 3.17171C25.7829 -1.05724 19.675 -1.05724 17.4348 3.17171L3.0952 30.1455C-5.6873 45.1869 5.26682 64 22.7861 64H23.2661C40.534 64 51.3052 45.4841 42.6827 30.6713L28.0483 3.17171Z" fill="white" />
</svg>);

export const IconStar = () => (<svg width="64" height="62" viewBox="0 0 64 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 51.2238L47.5195 60.6103C50.3617 62.3306 53.8395 59.7876 53.0916 56.5715L48.978 38.9204L62.7025 27.0283C65.2081 24.8593 63.8618 20.7457 60.5709 20.4839L42.5084 18.9507L35.4405 2.27184C34.169 -0.757278 29.831 -0.757278 28.5595 2.27184L21.4916 18.9133L3.4291 20.4465C0.138208 20.7083 -1.20807 24.8219 1.2975 26.9909L15.022 38.883L10.9084 56.5341C10.1605 59.7502 13.6383 62.2932 16.4805 60.5729L32 51.2238Z" fill="black" />
</svg>);
