import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import { BookId, UserId } from "../handlers/globals";
import { Defaults, QueryCase, QueryId, ToastId, WebRoutes } from "../handlers/enums";
import { GetData, MutateData } from "../handlers/axios";
import ComponentState from "./components/states";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Input, Popconfirm, Slider, Spin } from "antd";
import { PushpinFilled } from "@ant-design/icons";
import { notifyError } from "../handlers/extensions";
import ComponentBookIcon from "./components/bookicon";

export function SectionSearchUserTbr(props) {
    const { t } = useTranslation();

    const [userId,] = useAtom(UserId);

    const responseUser = GetData(QueryId.SelectUserLite, { UserId: userId });

    const response = GetData(QueryId.SelectUserBooks, { UserId: userId, Mode: props.mode });

    const [searchInput, setSearchInput] = useState(Defaults.StringEmpty);

    const [freezeActions, setFreezeActions] = useState(false);

    const actionYearGoal = MutateData(QueryId.UpdateUserYearGoal,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onYearGoal = (properties) => {
        properties.UserId = userId;

        setFreezeActions(true);
        actionYearGoal.mutate(properties);
    }

    return (responseUser.isLoading || response.isLoading ? <Spin size="large" className="mx-auto w-full mt-5" /> : responseUser.isFalse || response.isFalse ? <ComponentState error /> : response.isEmpty ? <ComponentState empty /> : <>
        <div className="flex mb-2">
            <p className="whitespace-nowrap my-auto mr-2 text-custom-white">{t("yearly_goal")}</p>
            <Slider className="w-full" marks={{ 0: "0", 24: "24" }} step={2} min={0} max={24} defaultValue={responseUser.data.yearlyBookGoal} onChangeComplete={(event) => { onYearGoal({ Goal: event }); }} />
        </div>
        <Input className="mb-2 custom-color-placeholder" size="large" onChange={(value) => setSearchInput(value.target.value)} placeholder={t("placeholder_searchbooks")} allowClear />
        <Divider className="bg-custom-darkgrey" />
        {response.data.filter((book) => { return book.title.toLowerCase().includes(searchInput.toLowerCase()) || book.authors.toLowerCase().includes(searchInput.toLowerCase()) }).map((book) => {
            return <div key={book.id}>
                <Book book={book} />
            </div>
        })}
    </>);
}

function Book(props) {
    const { t } = useTranslation();

    const [userId,] = useAtom(UserId);

    const [freezeActions, setFreezeActions] = useState(false);

    const [hidden, setHidden] = useState(false);

    const actionDelete = MutateData(QueryId.ToggleUserBook,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { setHidden(true); } }
    ]);

    const onDelete = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.id;

        setFreezeActions(true);
        actionDelete.mutate(properties);
    };

    const navigate = useNavigate();

    const [, setBookId] = useAtom(BookId);

    return (hidden ? null : <>
        <div className="flex mb-2 relative">
            <Popconfirm title={t("confirm_delete_book_title")} description={t("confirm_delete_book_description")}
                onConfirm={() => { onDelete({}); }}
                okText={t("yes")} cancelText={t("no")}
                okButtonProps={{ className: "!border-none hover:!bg-custom-orange" }}
                cancelButtonProps={{ className: "hover:!text-custom-white" }}>
                <Button disabled={freezeActions} className="absolute left-[84px] top-1 z-10 !border-custom-red !bg-custom-red disabled:!bg-custom-red active:!bg-custom-red" icon={<PushpinFilled />} size={"large"} />
            </Popconfirm>
            <ComponentBookIcon book={props.book} />
            <div className="ml-5 text-custom-white">
                <p>{props.book.title}</p>
                <p className="font-semibold">{props.book.authors.replace(",", ", ")}</p>
                <p className="text-nowrap mb-2">{t("page_amount")} {props.book.pageCount === 0 ? t("unknown") : props.book.pageCount}</p>
                <Button disabled={freezeActions} className="bg-[#3b82f6aa] disabled:bg-[#3b82f6aa]" onClick={() => { setBookId(props.book.id); navigate(WebRoutes.Book); }} size={"large"}>{t("view_book")}</Button>
            </div>
        </div>
        <Divider className="bg-custom-darkgrey" />
    </>);
}