import "./assets/styling/overwrite.css";
import "./assets/styling/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n.jsx";
import "./web.config";
import Home from "./pages/home.jsx";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import Config from "./variables.json";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import moment from "moment";
import { JwtKey } from "./handlers/extensions.jsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Provider as JotaiProvider } from "jotai";
import { WebRoutes } from "./handlers/enums";
import Book from "./pages/book.jsx";
import { ConfigProvider } from "antd";
import { registerLicense } from "@syncfusion/ej2-base";

const queryClient = new QueryClient();

axios.defaults.baseURL = Config.Variables.ApiUrl;
axios.defaults.headers.common["x-functions-key"] = process.env.REACT_APP_API_KEY;
axios.defaults.headers.common["Authorization"] = `Bearer ${await JwtKey()}`;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

moment.updateLocale("en", { months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] });
moment.updateLocale("nl", { months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"] });

dayjs.extend(customParseFormat);

registerLicense("ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdEBjXn5fcnRTT2ZV");

const root = ReactDOM.createRoot(document.getElementById("root"));
// const { darkAlgorithm } = theme;
root.render(
  <React.StrictMode>
    <ToastContainer autoClose={6000} limit={4} theme="colored" position="top-center" pauseOnHover={true} newestOnTop={true} pauseOnFocusLoss={true} closeOnClick={true} />
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <ConfigProvider theme={{
          components: {
            Button: {
              defaultHoverBg: "#ff7d08",
              defaultHoverBorderColor: "#ff7d08",
              defaultHoverColor: "#12130F",
              defaultActiveBg: "#ff7d08",
              defaultActiveBorderColor: "#ff7d08",
              defaultActiveColor: "#12130F",
              colorBgContainerDisabled: "#ff7d08",
              borderColorDisabled: "#ff7d08",
              colorTextDisabled: "#12130F",
              defaultBorderColor: "#4096ff"
            },
          },
        }}>
          <div className="bg-custom-darkgrey">
            <div className="max-w-96 mx-auto text-custom-orange">
              <BrowserRouter>
                <Routes>
                  <Route path={WebRoutes.Home} element={<Home />} />
                  <Route path={WebRoutes.Book} element={<Book />} />
                  <Route path={"*"} element={<Navigate replace to={WebRoutes.Home} />} />
                </Routes>
              </BrowserRouter>
            </div>
          </div>
        </ConfigProvider>
      </JotaiProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode >
);