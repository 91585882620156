import { useTranslation } from "react-i18next";
import DatePicker from "react-mobile-datepicker";

export default function ComponentDateTimePicker(props) {
  const { t } = useTranslation();

  const dateTime = new Date();
  dateTime.setMinutes(0);
  dateTime.setSeconds(0);
  dateTime.setMilliseconds(0);
  const maxDateTime = new Date();
  maxDateTime.setMonth(dateTime.getMonth() + 2);
  const minDateTime = new Date();
  minDateTime.setMonth(dateTime.getMonth() - 2);

  return (
    <DatePicker
      isOpen={props.open}
      showCaption={true}
      onCancel={props.cancel}
      onSelect={props.confirm}
      theme={"dark"}
      headerFormat={"DD-MM-YYYY hh:mm"}
      confirmText={t("confirm")}
      cancelText={t("cancel")}
      value={dateTime}
      min={minDateTime}
      max={maxDateTime}
      dateConfig={{
        "year": {
          format: "YYYY",
          caption: t("year"),
          step: 1,
        },
        "month": {
          format: "MM",
          caption: t("month"),
          step: 1,
        },
        "date": {
          format: "DD",
          caption: t("day"),
          step: 1,
        },
        "hour": {
          format: "hh",
          caption: t("hour"),
          step: 1,
        },
        "minute": {
          format: "mm",
          caption: t("minute"),
          step: 15,
        }
      }}
    />
  );
}