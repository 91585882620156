import { Button, Form, Layout, Segmented, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Defaults, QueryCase, QueryId, Section, ToastId, WebRoutes } from "../handlers/enums";
import { ComponentFormInput, ComponentFormPassword } from "./components/forms/input";
import ComponentFooter from "./components/footer";
import { SectionSearch } from "./SectionSearch";
import { LastHomeSegment, Loading, UserId } from "../handlers/globals";
import { useAtom } from "jotai";
import { Navigate } from "react-router-dom";
import { SectionSearchUserTbr } from "./SectionSearchUserTbr";
import { SectionSearchUserDone } from "./SectionSearchUserDone";
import { MutateData } from "../handlers/axios";
import { notifyWarning } from "../handlers/extensions";

export default function Home() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const { t } = useTranslation();

  const [userId, setUserId] = useAtom(UserId);

  const [isValidated, setIsValidated] = useState(userId);
  const [username, setUsername] = useState(Defaults.StringEmpty);
  const [password, setPassword] = useState(Defaults.StringEmpty);

  const [freezeActions, setFreezeActions] = useState(false);

  const actionLogin = MutateData(QueryId.Login,
    () => { setFreezeActions(false); }, [
    { case: QueryCase.Success, onCase: (data) => { setUserId(data.data); setIsValidated(true); } },
    { case: QueryCase.Error, onCase: () => { notifyWarning(ToastId.ActionWarning); } }
  ]);

  const onLogin = (properties) => {
    properties = {};
    properties.Username = username;
    properties.Password = password;

    setFreezeActions(true);
    actionLogin.mutate(properties);
  };

  const [segment, setSegment] = useAtom(LastHomeSegment);

  const [loading,] = useAtom(Loading);

  return (
    <>
      {loading ? <div className="fixed w-screen h-screen bg-[#2525257d] z-[1000] flex justify-center pt-[50vh]" onClick={() => { }}><Spin size="large" /></div> : null}
      <Layout className="!bg-custom-grey">
        <ComponentHeader />
        <Content className="min-h-[100vh] p-4 relative">
          {!isValidated ? <>
            <p className="font-semibold text-lg mb-2 text-custom-white text-center">{t("welcome")}</p>
            <Form>
              <ComponentFormInput field={"form_field_username"} nolabel placeholder={"placeholder_username"} required onChange={(event) => { setUsername(event.target.value); }} />
              <ComponentFormPassword field={"form_field_password"} nolabel placeholder={"placeholder_password"} required onChange={(event) => { setPassword(event.target.value); }} />
              <Button className="w-full font-semibold" disabled={freezeActions} loading={freezeActions} onClick={() => { onLogin({}) }}>{t("login")}</Button>
            </Form>
          </> : <>
            <Segmented
              options={[{ label: t(Section.Search), value: Section.Search }, { label: t(Section.TBR), value: Section.TBR }, { label: t(Section.Done), value: Section.Done }]}
              onChange={(value) => { setSegment(value); }}
              value={segment}
              className="mb-3 flex font-semibold bg-custom-darkgrey text-custom-white"
            />
            {segment === Section.Search ? <SectionSearch />
              : segment === Section.TBR ? <SectionSearchUserTbr mode={Section.TBR} />
                : segment === Section.Done ? <SectionSearchUserDone mode={Section.Done} />
                  : <Navigate replace to={WebRoutes.Home} />
            }
          </>
          }
        </Content>
        <ComponentFooter />
      </Layout>
    </>
  );
}