import { Empty, Result } from "antd";
import { useTranslation } from "react-i18next";

export default function ComponentState(props) {
  const { t } = useTranslation();

  return (
    props.empty ? <Empty className="childern-text-custom-white" description={props.description ? props.description : t("empty_data")} /> : <Result className="childern-text-custom-white"
      status={props.status ? props.status : props.error ? 500 : 500}
      title={props.title ? props.title : props.error ? t("generic_error") : t("generic_error")}
      subTitle={props.description ? props.description : props.error ? t("generic_error_description") : t("generic_error_description")}
      extra={props.extra}
    />
  );
}
