import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { Defaults, Regex } from "../../../handlers/enums";

export function ComponentFormInput(props) {
    const { t } = useTranslation();

    let rules = [];
    const required = { required: true, whitespace: true, message: <span className="block mt-1 mb-3">{t("form_field_required")}</span> };
    const email = { required: true, type: "email", message: <span className="block mt-1 mb-3">{t("form_field_required_format_email")}</span> };
    const url = { required: true, type: "url", message: <span className="block mt-1 mb-3">{t("form_field_required_format_url")}</span> };
    const phone = { required: true, pattern: Regex.Phone, message: <span className="block mt-1 mb-3">{t("form_field_required_format_phone")}</span> };
    const asciilite = { required: true, pattern: Regex.AlphaNumeric, message: <span className="block mt-1 mb-3">{t("form_field_required_format_alphanumeric")}</span> };
    const numeric = { required: true, pattern: Regex.Numeric, message: <span className="block mt-1 mb-3">{t("form_field_required_format_numeric")}</span> };
    const alpha = { required: true, pattern: Regex.Alpha, message: <span className="block mt-1 mb-3">{t("form_field_required_format_alpha")}</span> };

    if (props.required) { rules.push(required); }
    if (props.email) { rules.push(email); }
    else if (props.phone) { rules.push(phone); }
    else if (props.url) { rules.push(url); }
    else if (props.asciilite) { rules.push(asciilite); }
    else {
        if (props.numeric) { rules.push(numeric); }
        else if (props.alpha) { rules.push(alpha); }
    }

    let onInput = (event) => {
        (props.phone && event.target.value.trimStart()?.charAt(0) === "0") ? event.target.value.trimStart().substring(1) : event.target.value.trimStart();
    };
    const upperCase = (event) => { event.target.value = props.unspaced ? event.target.value.toUpperCase().replaceAll(Defaults.Space, Defaults.StringEmpty) : event.target.value.toUpperCase().trimStart(); };
    const lowerCase = (event) => { event.target.value = props.unspaced ? event.target.value.toLowerCase().replaceAll(Defaults.Space, Defaults.StringEmpty) : event.target.value.toLowerCase().trimStart(); };

    if (props.upper) { onInput = upperCase }
    else if (props.lower) { onInput = lowerCase }

    return (
        <Form.Item className="custom-color-placeholder" name={props.field} label={props.nolabel ? null : props.label ? props.label : t(props.field)} hasFeedback={props.feedback} initialValue={props.value}
            rules={props.required ? rules : undefined}>
            <Input onChange={props.onChange} readOnly={props.read} placeholder={t(props.placeholder)} value={props.value} disabled={props.disabled} allowClear={props.clear} onInput={onInput} />
        </Form.Item>
    )
}

export function ComponentFormPassword(props) {
    const { t } = useTranslation();

    let rules = [];
    const required = { required: true, whitespace: true, message: <span className="block mt-1 mb-3">{t("form_field_required")}</span> };
    const minimum = { required: true, min: 8, message: <span className="block mt-1 mb-3">{t("form_field_required_format_minlength")}</span> };
    const special = { required: true, pattern: Regex.AnySpecial, message: <span className="block mt-1 mb-3">{t("form_field_required_format_specialchar")}</span> };

    if (props.required) { rules.push(required); }
    if (props.requirements) { rules.push(minimum); rules.push(special); }

    return (
        <Form.Item className="custom-color-placeholder" name={props.field} label={props.nolabel ? null : props.label ? props.label : t(props.field)} hasFeedback={props.feedback}
            rules={props.required ? rules : undefined}>
            <Input.Password placeholder={t(props.placeholder)} allowClear={props.clear} onChange={props.onChange} />
        </Form.Item>
    )
}

export function ComponentFormTextarea(props) {
    const { TextArea } = Input;
    const { t } = useTranslation();

    let rules = [];
    const required = { required: true, whitespace: true, message: <span className="block mt-1 mb-3">{t("form_field_required")}</span> };
    const ascii = { required: true, pattern: Regex.AlphaNumericSpaces, message: <span className="block mt-1 mb-3">{t("form_field_required_format_alphanumeric")}</span> };

    if (props.required) { rules.push(required); }
    if (props.ascii) { rules.push(ascii); }

    return (
        <Form.Item name={props.field} label={props.field ? t(props.field) : <></>} hasFeedback={props.feedback}
            rules={props.required ? rules : undefined} initialValue={props.value}>
            <TextArea readOnly={props.read} value={props.value} className={props.resize ? Defaults.StringEmpty : "!resize-none"} rows={props.autoSize ? 1 : 4} autoSize allowClear={props.clear} disabled={props.disabled} showCount={props.counter} placeholder={props.placeholder} maxLength={props.max} />
        </Form.Item>
    )
}