import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./assets/localization/en.json";
import nederlands from "./assets/localization/nl.json";
import { Settings } from "./handlers/enums";

const resources = {
    en: { translation: english },
    nl: { translation: nederlands }
};

const keys = new Set();

i18n.use(initReactI18next).init({
    resources: resources,
    lng: "en",
    fallbackLng: "nl",
    lowerCaseLng: true,
    saveMissing: true,
    missingKeyHandler: (_lngs, _ns, key, _fallbackValue, _updateMissing, _options) => { keys.add(key); return key; }
});

let lastCount = 0;
if (Settings.TrackMissingTranslationKeys) {
    setInterval(() => { if (lastCount < keys.size) { lastCount = keys.size; console.warn(keys); } }, 5000);
}

export default i18n;