import { Image, Watermark } from "antd";

export default function ComponentBookIcon(props) {
  return (props.book.isMature
    ? <Watermark content="18+" font={{ color: "#ff0000" }} gap={[32, 192 * 2]}>
      <BookIcon thumbnail={props.book.thumbnail} />
    </Watermark>
    : <BookIcon thumbnail={props.book.thumbnail} />
  );
}

function BookIcon(props) {
  return (
    <div className="flex justify-center">
      <Image width={128} height={192} className="book_effect min-w-[128px] max-h-[192px] rounded-lg" src={props.thumbnail} preview={false} />
    </div>
  );
}