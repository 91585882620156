import { Image } from "antd";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/sprites/logo_og_books.png";
import { Header } from "antd/es/layout/layout";
import { WebRoutes } from "../../handlers/enums";

export default function ComponentHeader(props) {
  return (
    <>
      <Header className="sticky lg:justify-around flex justify-between py-2 px-4 top-0 z-50 border-b backdrop-blur-sm shadow-sm bg-[#dddddd35] p-0 h-auto leading-normal">
        <Link className="flex focus-visible:outline-none" to={WebRoutes.Home}>
          <Image height={50} width={50} className="min-w-[50px] max-h-[50px] hover:invert" src={LogoIcon} preview={false} />
        </Link>
      </Header>
    </>
  );
}
