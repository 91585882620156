import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import ComponentHeader from "./components/header";
import React, { useEffect } from "react";
import ComponentFooter from "./components/footer";
import { SectionBook } from "./SectionBook";

export default function Book() {
  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <>
      <Layout className="!bg-custom-grey">
        <ComponentHeader />
        <Content className="min-h-[100vh] p-4">
          <SectionBook />
        </Content>
        <ComponentFooter />
      </Layout>
    </>
  );
}