import { Defaults, Language, QueryCase, QueryId, WebRoutes } from "../handlers/enums";
import { Button, Divider, Dropdown, Input, Pagination, Popconfirm, Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined, PushpinOutlined, PushpinFilled } from "@ant-design/icons";
import { useState } from "react";
import Flag from "react-world-flags";
import { MutateData } from "../handlers/axios";
import { useAtom } from "jotai";
import { UserId, SearchBooks, BookId, Loading } from "../handlers/globals";
import { useNavigate } from "react-router-dom";
import ComponentState from "./components/states";
import ComponentBookIcon from "./components/bookicon";

export function SectionSearch(props) {
    const { t } = useTranslation();

    const [userId,] = useAtom(UserId);

    const [searchInput, setSearchInput] = useState(Defaults.StringEmpty);

    const [searchBooks, setSearchBooks] = useAtom(SearchBooks);

    const [page, setPage] = useState(Defaults.Number);

    const actionSearch = MutateData(QueryId.SelectGoogleBooks,
        () => { setFreezeActions(false); setLoading(false); }, [
        { case: QueryCase.Success, onCase: (data) => { window.scrollTo(0, 0); setSearchBooks(data.data); } }
    ]);

    const [, setLoading] = useAtom(Loading);

    const [freezeActions, setFreezeActions] = useState(false);

    const [language, setLanguage] = useState(Language.English);

    const onSearch = (properties) => {
        if (!searchInput) { return; }

        properties.UserId = userId;
        properties.SearchInput = searchInput;
        properties.LanguageRestriction = language;

        setLoading(true);
        setFreezeActions(true);
        actionSearch.mutate(properties);
    };

    const languageOptions = [
        {
            label: <Flag className={`cursor-pointer${language === Language.English ? " hidden" : Defaults.StringEmpty}`} code={Language.EnglishAlt} width={40} onClick={() => { setLanguage(Language.English); }} />
        },
        {
            label: <Flag className={`cursor-pointer${language === Language.Dutch ? " hidden" : Defaults.StringEmpty}`} code={Language.Dutch} width={40} onClick={() => { setLanguage(Language.Dutch); }} />
        }
    ];

    return (<>
        <Input suffix={
            <Dropdown
                menu={{ items: languageOptions }}
                rootClassName="dropdown_language"
                trigger={["click"]}
                className={"min-w-[30px]"}
            >
                <div>
                    <Flag className="cursor-pointer" width={30} code={
                        language === Language.English
                            ? Language.EnglishAlt
                            : language === Language.Dutch
                                ? Language.Dutch
                                : Language.Dutch
                    } />
                </div>
            </Dropdown>
        } className="mb-2 custom-color-placeholder" size="large" addonAfter={<div className="relative">{searchInput && !freezeActions ? <div className="animate-ping rounded-full w-4 h-4 absolute bg-custom-white left-[10px] top-[10px] pointer-events-none" /> : null}<SearchOutlined onClick={() => { onSearch({ PageIndex: Defaults.Number }); setPage(Defaults.Number); }} /></div>} onChange={(value) => setSearchInput(value.target.value)} disabled={freezeActions} placeholder={t("placeholder_searchbooks")} allowClear />
        {searchBooks.hiddenOnPage ? <div className="flex justify-center mt-1 mb-2"><Tooltip placement="bottom" title={t("tooltip_hidden_books")}><Tag color="processing">{searchBooks.hiddenOnPage} {searchBooks.hiddenOnPage === 1 ? t("book_hidden") : t("books_hidden")}</Tag></Tooltip></div> : null}
        <Pagination className="mb-3 mt-2 justify-center flex simple_pagination" onChange={(value) => { if (value !== page) { onSearch({ PageIndex: value }); setPage(value); } }} defaultPageSize={20} defaultCurrent={page} current={page} total={searchBooks.totalItems} />
        <Divider className="bg-custom-darkgrey" />
        {!searchBooks || !searchBooks.booksLite || !searchBooks.booksLite.filter((book) => { return !book.isDiscover; }) ? <ComponentState empty description={t("search_filter_required")} /> : searchBooks.booksLite.filter((book) => { return !book.isDiscover; }).map((book) => {
            return <div key={book.bookId}>
                <Book book={book} />
            </div>
        })}
        <Pagination className="justify-center flex simple_pagination" hideOnSinglePage={true} onChange={(value) => { if (value !== page) { onSearch({ PageIndex: value }); setPage(value); } }} defaultPageSize={20} defaultCurrent={page} current={page} total={searchBooks.totalItems} />
        {!searchBooks || !searchBooks.booksLite || !searchBooks.booksLite.filter((book) => { return book.isDiscover; }) ? null : <>
            <Divider className="bg-custom-darkgrey" />
            <p className="text-custom-white text-2xl text-center mb-6">{t("discover_title")}</p>
            {searchBooks.booksLite.filter((book) => { return book.isDiscover; }).map((book) => {
                return <div key={book.bookId}>
                    <Book book={book} />
                </div>
            })}
        </>}
    </>);
}

function Book(props) {
    const { t } = useTranslation();

    const [filled, setFilled] = useState(props.book.isMarked);

    const [userId,] = useAtom(UserId);

    const [freezeActions, setFreezeActions] = useState(false);

    const actionBookmark = MutateData(QueryId.ToggleUserBook,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { setFilled(true); toggleMarked(props.book.bookId, true); } },
        { case: QueryCase.Error, onCase: () => { setFilled(false); toggleMarked(props.book.bookId, false); } }
    ]);

    const onBookmark = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;

        setFreezeActions(true);
        actionBookmark.mutate(properties);
    };

    const actionDelete = MutateData(QueryId.ToggleUserBook,
        () => { setFreezeActions(false); }, [
        { case: QueryCase.Success, onCase: () => { setFilled(false); toggleMarked(props.book.bookId, true); } },
        { case: QueryCase.Error, onCase: () => { setFilled(true); toggleMarked(props.book.bookId, false); } }]);

    const onDelete = (properties) => {
        properties = {};
        properties.UserId = userId;
        properties.BookId = props.book.bookId;

        setFreezeActions(true);
        actionDelete.mutate(properties);
    };

    const [, setSearchBooks] = useAtom(SearchBooks);

    const toggleMarked = (bookId, forceMark) => {
        setSearchBooks(prevState => ({
            booksLite: prevState.booksLite.map(book =>
                book.bookId === bookId ? { ...book, isMarked: forceMark ?? !book.isMarked } : book
            )
        }));
    };

    const navigate = useNavigate();

    const [, setBookId] = useAtom(BookId);

    return (
        <>
            <div className="flex mb-2 relative">
                {!filled
                    ? <Button disabled={freezeActions} className="absolute left-[84px] top-1 z-10 bg-[#3b82f6aa] disabled:bg-[#3b82f6aa]" onClick={() => { setFilled(!filled); toggleMarked(props.book.bookId); onBookmark({}); }} icon={<PushpinOutlined />} size={"large"} />
                    : <Popconfirm title={t("confirm_delete_book_title")} description={t("confirm_delete_book_description")}
                        onConfirm={() => { setFilled(!filled); toggleMarked(props.book.bookId); onDelete({}); }}
                        okText={t("yes")} cancelText={t("no")}
                        okButtonProps={{ className: "!border-none hover:!bg-custom-orange" }}
                        cancelButtonProps={{ className: "hover:!text-custom-white" }}>
                        <Button disabled={freezeActions} className="absolute left-[84px] top-1 z-10 !border-custom-red !bg-custom-red disabled:!bg-custom-red active:!bg-custom-red" icon={<PushpinFilled />} size={"large"} />
                    </Popconfirm>
                }
                <ComponentBookIcon book={props.book} />
                <div className="ml-5 text-custom-white">
                    <p>{props.book.title.length > 69 ? props.book.title.substring(Defaults.Number, 50) + "..." : props.book.title}</p>
                    <p className="font-semibold">{props.book.authors.join(", ")}</p>
                    <p className="text-nowrap mb-2">{t("page_amount")} {props.book.pageCount === 0 ? t("unknown") : props.book.pageCount}</p>
                    <Button disabled={freezeActions} className="hover:bg-[#3b82f6aa] disabled:bg-[#3b82f6aa]" onClick={() => { setBookId(props.book.bookId); navigate(WebRoutes.Book); }} size={"large"}>{t("view_book")}</Button>
                </div>
            </div>
            <Divider className="bg-custom-darkgrey" />
        </>
    )
}